@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  box-sizing: border-box;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
